const nombreIdioma = [
  { id: "ESPANOL", text: "Español" },
  { id: "CATALAN", text: "Catalán" },
  { id: "VALENCIANO", text: "Valenciano" },
  { id: "EUSKERA", text: "Euskera" },
  { id: "GALLEGO", text: "Gallego" },
  { id: "INGLES", text: "Inglés" },
  { id: "FRANCES", text: "Francés" },
  { id: "ALEMAN", text: "Alemán" },
  { id: "ITALIANO", text: "Italiano" },
  { id: "ARABE", text: "Árabe" },
  { id: "RUSO", text: "Ruso" },
  { id: "HINDI", text: "Hindi" },
  { id: "PORTUGUES", text: "Portugués" },
  { id: "POLACO", text: "Polaco" },
  { id: "RUMANO", text: "Rumano" },
  { id: "UCRANIANO", text: "Ucraniano" },
  { id: "HUNGARO", text: "Húngaro" },
  { id: "HOLANDES", text: "Holandés" },
  { id: "TURCO", text: "Turco" },
  { id: "SUECO", text: "Sueco" },
  { id: "MANDARIN", text: "Mandarín" },
  { id: "JAPONES", text: "Japonés" },
  { id: "COREANO", text: "Coreano" },
  { id: "SWAHILI", text: "Swahili" },
];
const nivel = [
  { id: "ALTO", text: "Alto" },
  { id: "MEDIO", text: "Medio" },
  { id: "BAJO", text: "Bajo" },
];

export { nombreIdioma, nivel };
