
import { defineComponent } from "vue";
import { ErrorMessage, useField } from "vee-validate";
import CondicionesPrivacidadModal from "@/components/modals/CondicionesPrivacidad.vue";
import DeclaracionResponsableModal from "@/components/modals/DeclaracionResponsable.vue";
import { format } from "date-fns";
import { es } from "date-fns/locale";

import { Experiencia } from "@/types";

export default defineComponent({
  name: "step-1",
  components: {
    ErrorMessage,
    CondicionesPrivacidadModal,
    DeclaracionResponsableModal,
  },
  setup() {
    const { value: politicaPrivacidad } = useField("politicaPrivacidad");
    const { value: declaracionResponsable } = useField(
      "declaracionResponsable"
    );
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: dni } = useField("dni");
    const { value: direccion } = useField("direccion");
    const { value: localidad } = useField("localidad");
    const { value: telefono } = useField("telefono");
    const { value: email } = useField("email");
    const dia = format(new Date(), "PPPPP", { locale: es });
    const { value: experiencia } = useField<Array<Experiencia>>("experiencia");
    const { value: experienciaCuidadoMenores } = useField(
      "experienciaCuidadoMenores"
    );

    return {
      politicaPrivacidad,
      declaracionResponsable,
      nombre,
      apellidos,
      dni,
      direccion,
      localidad,
      telefono,
      email,
      dia,
      experiencia,
      experienciaCuidadoMenores,
    };
  },
});
