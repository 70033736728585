const horarios = [
  { id: 6, text: "6:00 a 7:00" },
  { id: 7, text: "7:00 a 8:00" },
  { id: 8, text: "8:00 a 9:00" },
  { id: 9, text: "9:00 a 10:00" },
  { id: 10, text: "10:00 a 11:00" },
  { id: 11, text: "11:00 a 12:00" },
  { id: 12, text: "12:00 a 13:00" },
  { id: 13, text: "13:00 a 14:00" },
  { id: 14, text: "14:00 a 15:00" },
  { id: 15, text: "15:00 a 16:00" },
  { id: 16, text: "16:00 a 17:00" },
  { id: 17, text: "17:00 a 18:00" },
  { id: 18, text: "18:00 a 19:00" },
  { id: 19, text: "19:00 a 20:00" },
  { id: 20, text: "20:00 a 21:00" },
  { id: 21, text: "21:00 a 22:00" },
];

const dias = [
  { id: 1, text: "Lunes" },
  { id: 2, text: "Martes" },
  { id: 3, text: "Miércoles" },
  { id: 4, text: "Jueves" },
  { id: 5, text: "Viernes" },
  { id: 6, text: "Sábado" },
  { id: 7, text: "Domingo" },
];

export { horarios, dias };
