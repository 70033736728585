
import { defineComponent } from "vue";
import { Tiempo } from "@/types";
import { ErrorMessage, useField } from "vee-validate";
import { dias, horarios } from "@/core/data/tiemposProfesionales";
import Combobox from "@/components/widgets/Combobox.vue";

export default defineComponent({
  name: "step-3-profesionales",
  components: {
    Combobox,
    ErrorMessage,
  },
  setup() {
    const { value: disponibilidad } = useField<Tiempo[]>("disponibilidad");
    const { value: laborables } = useField("laborables");
    const { value: finesDeSemana } = useField("finesDeSemana");
    const { value: festivos } = useField("festivos");
    const { value: urgencias } = useField("urgencias");
    const { value: noches } = useField("noches");
    const { value: detalleDisponibilidad } = useField("detalleDisponibilidad");
    return {
      dias,
      horarios,
      disponibilidad,
      laborables,
      finesDeSemana,
      festivos,
      urgencias,
      noches,
      detalleDisponibilidad,
    };
  },
  methods: {
    addInput() {
      this.disponibilidad.push({ dias: [], horas: [] });
    },
    removeInput(index) {
      if (this.disponibilidad.length > 1) {
        this.disponibilidad.splice(index, 1);
      } else {
        this.disponibilidad = [{ dias: [], horas: [] }];
      }
    },
  },
});
