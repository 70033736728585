const titulaciones = [
  { id: "GSEDUCACIONINFANTIL", text: "GS Educación Infantil" },
  {
    id: "GSANIMACIONSOCIOCULTURALYTURISTICA",
    text: "GS Animación sociocultural y turística",
  },
  {
    id: "GSENSENANZAYANIMACIONSOCIODEPORTIVA",
    text: "GS Enseñanza y Animación Sociodeportiva",
  },
  { id: "GSINTEGRACIONSOCIAL", text: "GS Integración Social" },
  { id: "MONITORDEOCIOYTIEMPOLIBRE", text: "Monitor/a de ocio y tiempo libre" },
  { id: "AUXILIARDEEDUCACIONINFANTIL", text: "Auxiliar de Educación infantil" },
  { id: "AUXILIARDEJARDINDEINFANCIA", text: "Auxiliar de Jardín de Infancia" },
];

const sinTitulaciones = [
  { id: "EGBESOEQUIVALENTE", text: "EGB/ESO o equivalente" },
  { id: "OTRAS", text: "Otras" },
];

export { titulaciones, sinTitulaciones };
