
import comarcas from "@/core/data/comarcas";
import { nivel, nombreIdioma } from "@/core/data/idiomas";
import {
  municipios,
  municipiosProvinciasComarcas,
} from "@/core/data/municipiosProvinciasComarcas";
import tratamientos from "@/core/data/tratamiento";
import vehiculos from "@/core/data/vehiculos";
import { Idioma } from "@/types";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { ErrorMessage, useField } from "vee-validate";
import { defineComponent, watch } from "vue";
import FlatPickr from "vue-flatpickr-component";
import { useI18n } from "vue-i18n/index";

//import Combobox from "@/components/widgets/Combobox.vue";

export default defineComponent({
  name: "step-1",
  components: {
    ErrorMessage,
    //Combobox,
    FlatPickr,
  },

  methods: {
    addInput() {
      this.idiomas.push({ nombreIdioma: [], nivel: [] });
    },
    removeInput(index) {
      if (this.idiomas.length > 1) {
        this.idiomas.splice(index, 1);
      } else {
        this.idiomas = [{ nombreIdioma: [], nivel: [] }];
      }
    },
  },

  setup() {
    const { t, te } = useI18n();

    const i18n = (text) => (te(text) ? t(text) : text);

    const { value: email } = useField("email");
    const { value: tratamiento } = useField("tratamiento");
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: dni } = useField("dni");
    const { value: telefono } = useField("telefono");
    const { value: fechaNacimiento } = useField("fechaNacimiento");
    const { value: direccion } = useField("direccion");
    const { value: comarca } = useField("comarca");
    const { value: provincia } = useField("provincia");
    const { value: localidad } = useField("localidad");
    const { value: carnetConducir } = useField("carnetConducir");
    const { value: vehiculoPropio, resetField: vehiculoPropioReset } =
      useField("vehiculoPropio");
    const { value: idiomas } = useField<Array<Idioma>>("idiomas");

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has("cm")) {
      const comarcaIdQr = urlParams.get("cm");
      if (comarcas.find((element) => element.id == comarcaIdQr)) {
        comarca.value = comarcaIdQr;
      }
    }

    watch(carnetConducir, () => {
      if (carnetConducir.value === false) {
        vehiculoPropioReset();
      }
    });

    watch(
      () => localidad.value,
      (val) => {
        let registro = municipiosProvinciasComarcas.find(
          (f) => f.municipioNombre === val
        );
        // Asignamos automaticamente la provincia y comarca
        comarca.value = registro?.comarcaId;
        provincia.value = registro?.provinciaNombre; // En IAM el ID de provincia es el nombre
      }
    );

    return {
      i18n,
      comarcas,
      municipios,
      email,
      tratamientos,
      tratamiento,
      nombre,
      apellidos,
      dni,
      telefono,
      fechaNacimiento,
      direccion,
      comarca,
      provincia,
      localidad,
      carnetConducir,
      vehiculoPropio,
      configFechaNacimiento: {
        disableMobile: true,
        dateFormat: "d/m/Y",
        locale: Spanish,
        maxDate: "today",
      },
      vehiculos,
      idiomas,
      nombreIdioma,
      nivel,
    };
  },
});
